import { Link } from "gatsby"
import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import { LogoModel } from "./header_model"

const Footer = () => {
  
  return (
    <footer className="main_footer_st">
        <Container>
        <Row>
          <Col className="col-cus-3 margin_bot_35">
            <h4 className="sub_title main_w_color pad_0">Jus-Pharma</h4>
            <Link to="/about" className="footer_menu_item dis_block">About</Link>
            <Link to="/investors" className="footer_menu_item dis_block">Investors</Link>
            <Link to="/solutions" className="footer_menu_item dis_block">Solutions</Link>
            <Link to="/participants" className="footer_menu_item dis_block">Participants</Link>
          </Col>
          <Col className="col-cus-3 margin_bot_35">
            <h4 className="sub_title main_w_color pad_0">Company</h4>
            <a href="https://jusglobal.com/" className="footer_menu_item dis_block">Jus-Global</a>
            <a href="https://jusglobal.com/application/" className="footer_menu_item dis_block">Partnerships</a>
          </Col>
          <Col className="col-cus-3 margin_bot_35">
            <h4 className="sub_title main_w_color pad_0">Other</h4>
            <Link to="terms" className="footer_menu_item dis_block">Terms & Conditions</Link>
            <Link to="privacy" className="footer_menu_item dis_block">Privacy Policy</Link>
          </Col>
          <Col className="col-cus-3 txt_align_right margin_bot_35">
            <h4 className="sub_title main_w_color pad_0">Get In Touch</h4>
            <Link to="mailto:info@jus-pharma.com" className="footer_menu_item dis_block">info@jus-pharma.com</Link>
            <Link to="/" className="footer_menu_item dis_block">+1 212.220.6850 </Link>
          </Col>
        </Row>

        <Row className="mobile_flex">
          <Col className="col-sm-6">
            <p className="footer_menu_item">
            <Image src={LogoModel.footerlogo} alt="Jus-Pharma" height="60" className=""/>
            &nbsp;&nbsp;© 2020 Jus-Global. All Rights Reserved.
            </p>
          </Col>
          <Col className="col-sm-6 margin_bot_35 txt_align_right">
            <p className="footer_menu_item">One World Trade Center<br />
            285 Fulton St. 85th FL, Suite 8500<br />
            New York City, NY 10007 </p>
          </Col>
        </Row>
        </Container>
    </footer>
  )
}

export default Footer
