import headerlogo from "../../images/JusPharma-Logo-small.png"
import footerlogo from "../../images/JusPharma-Logo-small-white.png"
import contacticon from "../../images/contact-comment.png"

export const navLinks = [
  { label: "About", link: "about" },
  { label: "Investors", link: "investors" },
  { label: "Solutions", link: "solutions" },
  { label: "Participants", link: "participants" },
]
export const subLinks = {
  label: "Demos", 
  submenu: [
    { label: "Participant", link: "https://invis.io/WPV9H2ZE6AN" },
    { label: "Investigator", link: "https://invis.io/WRVARWQA2K4" },
    { label: "C-Suite", link: "https://invis.io/3CVB1R56P8R" },
  ],
}

export const LogoModel = {
  headerlogo: headerlogo,
  footerlogo: footerlogo,
  contacticon: contacticon,
}
