import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Container, Navbar, Nav, Form, Image } from "react-bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css';
import '../custom.css';
import { navLinks, subLinks, LogoModel } from "./header_model"

const Header = () => {
  
  return (
      <Container>
        <div className="container fixed-top pad_mobile_0">
          <Navbar className="main_menu_st navbar jus-navbar" expand="lg" >
            <Navbar.Brand href="/">
              <Image src={LogoModel.headerlogo} alt="Jus-Pharma" height="60" className=""/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="toggle_menu_st">
              <Nav className="mr-auto">
                  {navLinks.map(item => {
                    return (
                      <Nav.Link
                        as={Link}
                        to={"/" + item.link}
                        key={item.label}
                        activeClassName="active"
                        className={`main_menu_item`}
                      >{item.label}</Nav.Link>
                    )
                  })}
                  <li>
                    <a
                      key={subLinks.label}
                      className={`main_menu_item pb_desktop_35`}
                    >{subLinks.label}
                    </a>
                    <div className="submenu_st">
                      {subLinks.submenu.map(item => {
                      return (
                        <a
                          href={item.link}
                          key={item.label}
                          activeclassname="active"
                          className={`main_menu_item`}
                          target='_blank'
                          rel="noreferrer"
                        >{item.label}</a>
                      )
                    })}
                    </div>
                  </li>
                </Nav>
                <Form inline>
                  <Link className="contact_us_btn_st" to="/contact"><Image src={LogoModel.contacticon} alt="Comment" height="23" className=""/>&nbsp;&nbsp;&nbsp;Contact Us</Link>
                </Form>
            </Navbar.Collapse>
          </Navbar> 
        </div>
          
      </Container>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
